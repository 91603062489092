import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import 'firebase/compat/firestore';
const db = firebase.firestore();

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue'),
        beforeEnter: (to, from, next) => {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    next({ path: 'mypage' });
                } else {
                    next();
                }
            });
        },
    },
    {
        path: '/select_use',
        name: 'select_use',
        component: () => import('../views/select_use.vue'),
        beforeEnter: (to, from, next) => {
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    await db
                        .collection('users_public')
                        .doc(user.uid)
                        .get()
                        .then((doc) => {
                            console.log('ドキュメントの有無', doc.data());
                            if (doc.data() == undefined) {
                                next();
                            } else {
                                next({ path: 'request_error' });
                            }
                        });
                } else {
                    next({ path: 'request_error' });
                }
            });
        },
    },
    {
        path: '/signup',
        name: 'signup',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/signup.vue'),
    },
    {
        path: '/unauthenticated',
        name: 'unauthenticated',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/unauthenticated.vue'),
    },
    //{
    // path: '/select_use',
    // name: 'select_use',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/select_use.vue'),
    //},
    {
        path: '/send_mail',
        name: 'send_mail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/send_mail.vue'),
    },
    {
        path: '/success',
        name: 'success',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/success.vue'),
    },
    {
        path: '/user/',
        name: 'user',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user.vue'),
    },
    {
        path: '/mypage',
        name: 'mypage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/mypage.vue'),
    },
    {
        path: '/account_setting_photo',
        name: 'account_setting_photo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_photo.vue'),
    },
    {
        path: '/account_setting_name',
        name: 'account_setting_name',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_name.vue'),
    },
    {
        path: '/account_setting_introduction',
        name: 'account_setting_introduction',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_introduction.vue'),
    },
    {
        path: '/account_setting_id',
        name: 'account_setting_id',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_id.vue'),
    },
    {
        path: '/account_setting_id_test',
        name: 'account_setting_id_test',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_id_test.vue'),
    },
    {
        path: '/account_setting_sex',
        name: 'account_setting_sex',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_sex.vue'),
    },
    {
        path: '/account_setting_single_word',
        name: 'account_setting_single_word',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_single_word.vue'),
    },
    {
        path: '/user_link_setting',
        name: 'user_link_setting',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_link_setting.vue'),
    },
    {
        path: '/user_link_add',
        name: 'user_link_add',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_link_add.vue'),
    },
    {
        path: '/setting_style',
        name: 'setting_style',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_style.vue'),
    },
    {
        path: '/user_preview',
        name: 'user_preview',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_preview.vue'),
    },
    {
        path: '/account_setting_twitter_id',
        name: 'account_setting_twitter_id',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_twitter_id.vue'),
    },
    {
        path: '/transfer_status',
        name: 'transfer_status',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/transfer_status.vue'),
    },
    {
        path: '/setting_transfer_personal_information',
        name: 'setting_transfer_personal_information',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_transfer_personal_information.vue'),
    },
    {
        path: '/request_error',
        name: 'request_error',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/request_error.vue'),
    },
    {
        path: '/setting_transfer_verification',
        name: 'setting_transfer_verification',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_transfer_verification.vue'),
    },
    {
        path: '/report_list',
        name: 'report_list',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/report_list.vue'),
    },
    //ここまでは確実に使用する

    {
        path: '/setting_point_multiplier',
        name: 'setting_point_multiplier',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_point_multiplier.vue'),
    },
    {
        path: '/sales_report',
        name: 'sales_report',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/sales_report.vue'),
    },
    {
        path: '/payment_setting',
        name: 'payment_setting',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/payment_setting.vue'),
    },
    {
        path: '/ranking/',
        name: 'ranking',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ranking.vue'),
    },
    {
        path: '/user_delete',
        name: 'user_delete',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_delete.vue'),
    },
    {
        path: '/user_delete_success',
        name: 'user_delete_success',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_delete_success.vue'),
    },
    {
        path: '/buy_point',
        name: 'buy_point',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/buy_point.vue'),
    },
    {
        path: '/buy_rank',
        name: 'buy_rank',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/buy_rank.vue'),
    },
    {
        path: '/success_buy_point',
        name: 'success_buy_point',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/success_buy_point.vue'),
    },
    {
        path: '/send_point',
        name: 'send_point',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/send_point.vue'),
    },
    {
        path: '/success_send_point/:id/:document_id',
        name: 'success_send_point',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/success_send_point.vue'),
    },
    {
        path: '/success_buy_rank/:id/:document_id',
        name: 'success_buy_rank',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/success_buy_rank.vue'),
    },
    {
        path: '/setting_transfer',
        name: 'setting_transfer',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_transfer.vue'),
    },
    {
        path: '/setting_transfer_test',
        name: 'setting_transfer_test',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_transfer_test.vue'),
    },
    {
        path: '/fan_page',
        name: 'fan_page',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/no_use/fan_page.vue'),
    },
    {
        path: '/search',
        name: 'search',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/search.vue'),
    },
    {
        path: '/fanclub_search_admin',
        name: 'fanclub_search_admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/no_use/fanclub_search_admin.vue'),
    },
    {
        path: '/setting_transfer_admin',
        name: 'setting_transfer_admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_transfer_admin.vue'),
    },
    {
        path: '/sale_history',
        name: 'sale_history',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/sale_history.vue'),
    },
    {
        path: '/follow_fanclub',
        name: 'follow_fanclub',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/follow_fanclub.vue'),
    },
    {
        path: '/change_mail_success',
        name: 'change_mail_success',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/change_mail_success.vue'),
    },
    {
        path: '/change_email',
        name: 'change_email',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/change_email.vue'),
    },
    {
        path: '/change_password',
        name: 'change_password',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/change_password.vue'),
    },
    {
        path: '/change_password_success',
        name: 'change_password_success',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/change_password_success.vue'),
    },
    {
        path: '/rank_payment',
        name: 'rank_payment',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/rank_payment.vue'),
    },
    {
        path: '/succsess_withdrawal',
        name: 'succsess_withdrawal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/succsess_withdrawal.vue'),
    },
    {
        path: '/withdrawal',
        name: 'withdrawal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/withdrawal.vue'),
    },
    {
        path: '/sales_confirmation',
        name: 'sales_confirmation',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/sales_confirmation.vue'),
    },
    {
        path: '/signup_admin',
        name: 'signup_admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/signup_admin.vue'),
    },
    {
        path: '/user_search_admin',
        name: 'user_search_admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_search.vue'),
    },
    {
        path: '/usersetting_admin',
        name: 'usersetting_admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/usersetting_admin.vue'),
    },
    {
        path: '/application_user_list',
        name: 'application_user_list',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/application_user_list.vue'),
    },
    {
        path: '/application_success',
        name: 'application_success',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/application_success.vue'),
    },
    {
        path: '/move_hub',
        name: 'move_hub',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/move_hub.vue'),
    },
    {
        path: '/send_change_password_mail',
        name: 'send_change_password_mail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/send_change_password_mail.vue'),
    },
    {
        path: '/send_reset_password_mail',
        name: 'send_reset_password_mail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/send_reset_password_mail.vue'),
    },
    {
        path: '/reset_password',
        name: 'reset_password',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/reset_password.vue'),
    },
    {
        path: '/fp_payment',
        name: 'fp_payment',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/fp_payment.vue'),
    },
    {
        path: '/setting_badge_point',
        name: 'setting_badge_point',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_badge_point.vue'),
    },
    {
        path: '/preview',
        name: 'preview',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/preview.vue'),
    },
    {
        path: '/sales_management',
        name: 'sales_management',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/sales_management.vue'),
    },
    {
        path: '/sales_analysis',
        name: 'sales_analysis',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/sales_analysis.vue'),
    },
    {
        path: '/user_analysis',
        name: 'user_analysis',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_analysis.vue'),
    },
    {
        path: '/UserDetailsChangeHistory',
        name: 'UserDetailsChangeHistory',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/UserDetailsChangeHistory.vue'),
    },
    {
        path: '/admin_search',
        name: 'admin_search',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/admin_search.vue'),
    },
    {
        path: '/top_reporters',
        name: 'top_reporters',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/top_reporters.vue'),
    },
    {
        path: '/_success_send_point/:id/:document_id',
        redirect: { name: 'success_send_point' },
    },
    {
        path: '/_success_buy_rank/:id/:document_id',
        redirect: { name: 'success_buy_rank' },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            // 現在の日付情報を取得
            if (user.emailVerified) {
                const after_hour = dayjs(Cookies.get('last_time')).add(1, 'h').format();
                console.log(after_hour);
                if (Cookies.get('login_check') == 'false') {
                    if (dayjs().isAfter(after_hour)) {
                        firebase
                            .auth()
                            .signOut()
                            .then(() => {});
                        next({ path: 'login' });
                    } else {
                        Cookies.set('last_time', dayjs().format());
                        next();
                    }
                } else {
                    next();
                }
            } else {
                next();
            }
        } else {
            next();
        }
    });
});

export default router;

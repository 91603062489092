<template>
    <header>
        <router-link to="/">
            <div class="hrader_logo left_style">FANFUN</div>
        </router-link>

        <v-btn @click="menu_show = !menu_show" icon>
            <div class="mypage_link">
                <img id="image" src="" v-if="login_user" />
                <img src="@/assets/img/nologin_image.png" v-if="logout_user" />
            </div>
        </v-btn>

        <router-link to="search">
            <div class="search_button">
                検索する
                <img class="search_logo" src="@/assets/img/search_logo.png" />
            </div>
        </router-link>

        <ul class="menu" v-if="menu_show">
            <router-link to="/signup">
                <li v-if="logout_user">新規登録</li>
            </router-link>
            <router-link to="/login">
                <li v-if="logout_user">ログイン</li>
            </router-link>
            <li v-if="login_user" @click="move_user()">マイページ</li>
            <router-link to="/mypage">
                <li v-if="login_user">設定</li>
            </router-link>
            <li v-if="login_user" class="red" @click="logout()">ログアウト</li>
        </ul>
        <div class="close_menu" v-if="menu_show" @click="menu_show = !menu_show"></div>
    </header>

    <div class="home_content">
        <!--<div class="home_card_wrap">
    <img class ="home_card" src="@/assets/img/top_visual_01.png">
    <h3>
      応援を超えて想いを届ける<br>
      マイクロギフティング
    </h3>
    <p>
      FANFUNは、応援だけでは伝えきれない、よりたく<br>
      さんの「好き」「ありがとう」「頑張れ」という想<br>
      いを、直接インフルエンサーに届けることが出来<br>
      る、ギフティングサービスです。
    </p>
  </div>

  <div class="home_card_wrap">
    <img class ="home_card" src="@/assets/img/top_visual_02.png">
    <h3>
      インフルエンサーに<br>
      ファンポイントを贈ろう
    </h3>
    <p>
      FANFUNを通じて寄付をすると、好きなアスリート<br>
      にファンポイントを贈ることができます。贈られた<br>
      ファンポイントによって、インフルエンサーの日々<br>
      の活動をサポートします。
    </p>
  </div>

  <div class="home_card_wrap">
    <img class ="home_card" src="@/assets/img/top_visual_03.png">
    <h3>
      あなたの想いが<br>
      インフルエンサーの力になる
    </h3>
    <p>
      届けられた寄付金は、活動資金となり、インフルエ<br>
      ンサー自身が自由な使途で活用することができます。
    </p>
  </div>

  <h2>参加するメリット</h2>

  <div class="home_card_wrap">
    <img class ="home_card" src="@/assets/img/top_visual_04.png">
    <h3>
      目標設定不要ですぐにスタート
    </h3>
    <p>
      目的や目標額などを設定する必要はありません。<br>
      最短1日で簡単に参加することができます。参加後す<br>
      ぐにご利用を開始することができます。
    </p>
  </div>

  <div class="home_card_wrap">
    <img class ="home_card" src="@/assets/img/top_visual_05.png">
    <h3>
      メディアを通してより多くのファンへ
    </h3>
    <p>
      Unlimが連携するメディアなどに掲載されることで、<br>
      より多くのファンからのギフティングを集めることが<br>
      できます。

    </p>
  </div>

  <div class="home_card_wrap">
    <img class ="home_card" src="@/assets/img/top_visual_06.png">
    <h3>
    アスリートへの高い分配率
    </h3>
    <p>
    集まった寄付金額の67〜83％が、財団から支援金と<br>
    して送られます。集まった支援金の使途は自由。<br>
    活動資金をはじめとして、様々な目的でご利用いただ<br>
    くことができます。
    </p>
  </div> -->

        <ul class="button_list">
            <router-link to="/signup">
                <li class="gray_button">新規登録</li>
            </router-link>
            <router-link to="/login">
                <li class="gray_button">ログイン</li>
            </router-link>
            <li class="gray_button" v-on:click="logout">ログアウト</li>
        </ul>

        <ul class="button_list">
            <li class="gray_button" @click="move_userpage()">自分のユーザーページ</li>

            <li class="gray_button" @click="move_fanpage()">自分のファンクラブページ</li>

            <li class="gray_button" @click="delete_admin()">管理者健軍の削除</li>

            <router-link to="/account_setting">
                <li class="gray_button">設定</li>
            </router-link>
        </ul>

        <ul class="button_list">
            <router-link to="/user_search">
                <li class="gray_button">ユーザー検索</li>
            </router-link>
            <router-link to="/fanclub_search">
                <li class="gray_button">ファンクラブ検索</li>
            </router-link>
        </ul>
    </div>
</template>

<script>
// @ is an alias to /src
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';
firebase.initializeApp({
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID,
});
console.log(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
const db = firebase.firestore();
export default {
    data: function () {
        return {
            login_user: false,
            logout_user: false,
            menu_show: false,
        };
    },
    methods: {
        move_user() {
            firebase.auth().onAuthStateChanged((firebaseUser) => {
                if (firebaseUser) {
                    var currentUser = firebaseUser.uid;

                    db.collection('user_profile')
                        .doc(currentUser)
                        .get()
                        .then((doc) => {
                            const uid = doc.data().original_id;
                            window.location.href = location.protocol + '//' + location.host + '/user' + '?' + uid;
                        });
                }
            });
        },
        logout() {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    // Sign-out successful.
                })
                .catch((error) => {
                    // eslint-disable-line
                    // An error happened.
                });
        },
        move_fanpage() {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    const uid = user.uid;

                    db.collection('fanclub')
                        .doc(uid)
                        .get()
                        .then((doc) => {
                            var fanclub_id = doc.data().fanclub_id;
                            window.location.href = 'http://' + location.host + '/fan_page' + '?' + fanclub_id;
                        });
                }
            });
        },
        move_userpage() {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    const uid = user.uid;
                    window.location.href = 'http://' + location.host + '/user' + '?' + uid;
                }
            });
        },
        delete_admin() {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    const uid = user.uid;
                }
            });
        },
    },
    mounted() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.login_user = true;
                this.logout_user = false;
            } else {
                this.login_user = false;
                this.logout_user = true;
            }
        });

        //ヘッダー用処理
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // eslint-disable-line

                const uid = user.uid;

                db.collection('users')
                    .doc(uid)
                    .get()
                    .then((doc) => {
                        var profile_img_flag = doc.data().profile_img_flag;

                        if (profile_img_flag == true) {
                            //プロフィール画像表示処理
                            var storageRef = firebase.storage().ref();
                            const DownloadTask = storageRef.child('profile_images/' + uid + '/profile_img');
                            DownloadTask.getDownloadURL().then((downloadURL) => {
                                document.getElementById('image').src = downloadURL;
                                document.getElementById('image').style.display = 'block';
                            });
                        } else {
                            var storageRef_02 = firebase.storage().ref();
                            const DownloadTask_02 = storageRef_02.child('no_image/' + '/noimage.png');
                            DownloadTask_02.getDownloadURL().then((downloadURL) => {
                                document.getElementById('image').src = downloadURL;
                                document.getElementById('image').style.display = 'block';
                            });
                        }
                    });
            } else {
                //eslint-disable-line
            }
        });
    },
};
</script>

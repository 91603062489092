<template ontouchstart="">
    <router-view />
    <div id="script_wrap"></div>

    <div id="loading" v-if="is_loading">
        <div class="spinner"></div>
    </div>
</template>

<style>
/* ファイルパスに従って@importntの後にファイルパスを書き込む */
@import './assets/css/style.css';
</style>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

// ページ内の高さ
const vh = window.innerHeight;

export default {
    name: 'app',
    data: function () {
        return {
            is_loading: true,
        };
    },
    mounted() {
        const authenticated = document.cookie.includes('authenticated');
        console.log(authenticated);
        if (authenticated != true) {
            window.location.href = location.protocol + '//' + location.host + '/auth';
        }
        // ローディング画面
        document.getElementById('loading').style.height = vh + 'px'; // 高さ指定
        window.addEventListener('load', () => {
            setTimeout(() => {
                this.is_loading = false;
            }, 1000);
        });

        //func_03();

        window.addEventListener('beforeunload', () => {
            // ここに処理内容
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    user.getIdTokenResult(true).then((result) => {
                        console.log(result.claims);

                        // 現在の日付情報を取得
                        const after_hour = dayjs(result.claims.exp * 1000)
                            .subtract(1, 'h')
                            .format();

                        if (dayjs().subtract(1, 'm').isAfter(after_hour)) {
                            firebase
                                .auth()
                                .signOut()
                                .then(() => {});
                        } else {
                            //none
                        }
                    });
                }
            });
        });
    },
};
</script>
